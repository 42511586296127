<script lang="ts">
const debug = false
</script>
<script setup lang="ts">
import type { EventBusKey } from '@vueuse/core'
import { kCollectToWarehouseEventBusKey, kSetWarehousePositionEventBusKey } from '@/lib/constants'
import { fromAsyncThrowable } from 'neverthrow'
import { Application, Assets, Sprite, Ticker } from 'pixi.js'

const canvas = useTemplateRef<HTMLCanvasElement>('canvas')
const warehousePosition = ref<{ x: number, y: number } | null>(null)


const eventBus = useEventBus(kCollectToWarehouseEventBusKey)
const warehousePosEventBus = useEventBus(kSetWarehousePositionEventBusKey)

const app = shallowRef<Application | null>()
let destroyed = false

function transformPosition(position: readonly [number, number]) {
  if (!canvas.value || !app.value)
    return { x: 0, y: 0 }

  const rect = canvas.value.getBoundingClientRect()
  const scaleX = window.innerWidth / rect.width
  const scaleY = window.innerHeight / rect.height

  return {
    x: (position[0] - rect.left) * scaleX,
    y: (position[1] - rect.top) * scaleY,
  }
}

// 缓动函数
function easeOutCubic(t: number): number {
  return 1 - (1 - t) ** 3
}

function onCollectEvent(event: typeof kCollectToWarehouseEventBusKey extends EventBusKey<infer S> ? S : unknown) {
  fromAsyncThrowable(async () => {
    if (destroyed || !warehousePosition.value || !app.value)
      return

    const texture = await Assets.load(event.cropImage)
    const spriteCount = 12 // 创建的精灵数量
    const spawnRadius = 40 // 生成精灵的范围半径

    const sprites: Sprite[] = []
    const startPositions: { x: number, y: number }[] = []

    const transformedPosition = transformPosition(event.fromPosition)
    const endPosition = { ...warehousePosition.value }

    for (let i = 0; i < spriteCount; i++) {
      const sprite = new Sprite(texture)
      sprite.setSize(30, 30)
      sprite.anchor.set(0.5, 0.5)

      // 在指定范围内随机生成位置
      const randomAngle = Math.random() * Math.PI * 2
      const randomRadius = Math.random() * spawnRadius
      const startX = transformedPosition.x + Math.cos(randomAngle) * randomRadius
      const startY = transformedPosition.y + Math.sin(randomAngle) * randomRadius

      sprite.position.set(startX, startY)
      sprite.alpha = 0 // 开始时完全透明

      app.value.stage.addChild(sprite)
      sprites.push(sprite)
      startPositions.push({ x: startX, y: startY })
    }

    const fadeInDuration = 300 // 渐入持续时间（毫秒）
    const pauseDuration = 200 // 停顿持续时间（毫秒）
    const moveDuration = 1000 // 移动持续时间（毫秒）
    const fadeOutDuration = 300 // 淡出持续时间（毫秒）
    const totalDuration = fadeInDuration + pauseDuration + moveDuration + fadeOutDuration
    let elapsedTime = 0

    function anim(ticker: Ticker) {
      elapsedTime += ticker.deltaMS

      const progress = Math.min(elapsedTime / totalDuration, 1)

      sprites.forEach((sprite, index) => {
        if (progress <= fadeInDuration / totalDuration) {
          // 渐入阶段
          sprite.alpha = easeOutCubic(progress / (fadeInDuration / totalDuration))
        }
        else if (progress <= (fadeInDuration + pauseDuration) / totalDuration) {
          // 停顿阶段
          sprite.alpha = 1
        }
        else if (progress <= (fadeInDuration + pauseDuration + moveDuration) / totalDuration) {
          // 移动阶段
          const moveProgress = (progress - (fadeInDuration + pauseDuration) / totalDuration) / (moveDuration / totalDuration)
          const easedMoveProgress = easeOutCubic(moveProgress)

          const startPosition = startPositions[index]
          const newX = startPosition.x + (endPosition.x - startPosition.x) * easedMoveProgress
          const newY = startPosition.y + (endPosition.y - startPosition.y) * easedMoveProgress

          sprite.position.set(newX, newY)
        }
        else {
          // 淡出阶段
          const fadeOutProgress = (progress - (fadeInDuration + pauseDuration + moveDuration) / totalDuration) / (fadeOutDuration / totalDuration)
          sprite.alpha = 1 - easeOutCubic(fadeOutProgress)
          sprite.scale.set(sprite.scale.x - 0.01, sprite.scale.y - 0.01)
        }
      })

      // 如果动画完成，移除所有精灵并停止动画
      if (progress >= 1) {
        sprites.forEach(sprite => app.value!.stage.removeChild(sprite))
        Ticker.shared.remove(anim)
      }
    }

    Ticker.shared.add(anim)
  })()
}

const resizeHandler = useThrottleFn(() => {
  if (typeof app.value?.renderer?.resize !== 'function')
    return
  app.value.renderer.resize(document.documentElement.clientWidth, document.documentElement.clientHeight)
}, 100, true, true)

useEventListener(window, 'resize', resizeHandler)

onMounted(() => {
  app.value = new Application()
  app.value.init({
    canvas: canvas.value!,
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
    backgroundAlpha: debug ? 0.1 : 0,
    resolution: 1,
  })

  eventBus.on(onCollectEvent)
  warehousePosEventBus.on((event) => {
    warehousePosition.value = transformPosition(event)
    console.info('📔 On set warehouse position:', warehousePosition.value)
  })
})

onUnmounted(() => {
  eventBus.off(onCollectEvent)
  app.value?.destroy()
  destroyed = true
})
</script>

<template>
  <canvas
    ref="canvas"
    class="warehouse-animation-overlay"
    :class="{
      'pointer-events-none': !debug,
    }"
    @mousedown="(event) => {
      onCollectEvent({ fromPosition: [event.clientX, event.clientY], cropImage: '/image/pay-coin.png' })
    }"
  />
</template>

<style lang="scss">
.warehouse-animation-overlay {
  @apply inset-0 fixed size-full select-none z-[100000];
}
</style>
